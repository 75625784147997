<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>Hr系统</el-breadcrumb-item>
        <el-breadcrumb-item>员工管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="query.keyword" prefix-icon="Search"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="danger" icon="delete" @click="deleter()">删除</el-button>

      <el-dropdown style="margin-left:20px;">
        <el-button plain type="primary">
          <el-icon style="margin-right:5px;">
            <Upload />
          </el-icon>
          数据操作
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="download()">导出信息</el-dropdown-item>
            <el-dropdown-item @click="visible = true">批量导入</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>


    <basic-table ref="table" class="table" :query="query" @handler="fetchs"></basic-table>

    <component ref="dialog" :visible="visible" @change="changeHandler" @submit="submitHandler" :columns="query.columns"
      is="importer" />
  </el-card>
</template>
      
<script>
import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
export default {
  name: "HrEmployee",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "employeeNumber", label: "工号", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "realName", label: "姓名", sortable: true, searchable: true, width: "85", showOverflowTooltip: true },
          { prop: "companyName", label: "公司名称", sortable: true, searchable: true, width: "130", showOverflowTooltip: true },
          { prop: "departmentName", label: "部门", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },

          { prop: "employmentStatus", label: "在职状态", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },

          { prop: "workCenter", label: "中心", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "workRegion", label: "区域", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "workLocation", label: "工作地点", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "jobCategory", label: "岗位类别", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "jobTitle", label: "岗位名称", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },

          { prop: "hireDate", label: "入职时间", sortable: true, width: "110", showOverflowTooltip: true },
          { prop: "regularizationDate", label: "转正时间", sortable: true, width: "110", showOverflowTooltip: true },

          { prop: "mobile", label: "手机号码", sortable: true, searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "idNumber", label: "身份证号码", sortable: true, searchable: true, width: "130", showOverflowTooltip: true },
          { prop: "gender", label: "性别", sortable: true, width: "90", showOverflowTooltip: true },

          { prop: "birthDate", label: "出生年月日", sortable: true, width: "120", showOverflowTooltip: true },
          { prop: "workYear", label: "工龄", sortable: true, width: "90", showOverflowTooltip: true },
          { prop: "highestEducation", label: "最高学历", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "ethnicity", label: "民族", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "maritalStatus", label: "婚否", sortable: true, width: "90", showOverflowTooltip: true },
          { prop: "politicalAffiliation", label: "政治面貌", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "graduatedSchool", label: "毕业学校", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "major", label: "所学专业", searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "graduationDate", label: "毕业时间", sortable: true, width: "120", showOverflowTooltip: true },
          { prop: "birthPlace", label: "籍贯", width: "90", showOverflowTooltip: true },
          { prop: "householdRegistration", label: "户籍", width: "90", showOverflowTooltip: true },
          { prop: "householdAddress", label: "户籍地址", width: "110", showOverflowTooltip: true },
          { prop: "currentAddress", label: "现住地址", width: "120", showOverflowTooltip: true },
          { prop: "emergencyContactPhone", label: "紧急联系人电话", searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "emergencyContactName", label: "紧急联系人姓名", searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "emergencyContactRelationship", label: "相互关系", width: "90", showOverflowTooltip: true },

          { prop: "contractType", label: "合同类型", sortable: true, searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "previousCompanyDuration", label: "上家公司在职时间", width: "130", showOverflowTooltip: true },
          { prop: "previousCompanyPosition", label: "上家公司在职岗位", searchable: true, width: "120", showOverflowTooltip: true },
          { prop: "previousCompanyName", label: "上家公司名称", searchable: true, width: "110", showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "createDate", label: "创建时间", sortable: true, width: "120", showOverflowTooltip: true },
          { prop: "updateUserName", label: "更新人", searchable: true, width: "90", showOverflowTooltip: true },
          { prop: "updateDate", label: "更新时间", sortable: true, width: "120", showOverflowTooltip: true },
        ],
        filters: [],
        sidx: "hireDate",
        sord: "asc"
      },
      visible: false
    };
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);
    this.fetchs();
  },
  methods: {
    fetchs() {
      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    },
    download() {
      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.export(params).then((res) => {
        this.$notify({
          title: '表格数据导出',
          message: res.content,
          type: res.type,
          duration: 10000
        });
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },

    deleter() {
      if (!this.$refs.table.chooseRow) {
        this.$message({ message: '请点击选择要删除的数据行', type: 'warning' });
        return;
      }

      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          this.$kaung.delete(that.$refs.table.chooseRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({ message: res.content, type: res.type });
              that.fetchs();
            }
          });
        });
    },

    changeHandler(value) {
      this.visible = value;
    },
    submitHandler(lists) {
      this.$kaung.fetch({ url: this.$options.name + '/Importer', data: lists }).then((res) => {
        if (res.code === 200) {
          this.$message({ message: res.content, type: res.type });
          this.fetchs();
        }
      });
    }
  },
};
</script>